import React from 'react';

export default {
  switchLang: '中文',
  comingSoon: '即將推出',
  menuOpen: 'menu',
  menuClose: 'close',
  watchFilm: '觀看影片',
  videoComingSoon: '影片即將上線',
  menu: [
    {
      text: '主頁',
      title: (
        <>
          在林畔
          <br />
          見未來
        </>
      ),
    },
    {
      text: '企業哲學',
      title: (
        <>
          藝術，
          <br />
          扎根於此
        </>
      ),
    },
    {
      text: '完美呈現',
      title: (
        <>
          匠心獨具
          <br />
          大師之作
        </>
      ),
    },
    {
      text: '地理位置',
      title: (
        <>
          <span>Kerrisdale</span>
          <br />
          的永恆傳說
        </>
      ),
    },
    {
      text: '精美設計',
      title: (
        <>
          每戶住宅，
          <br />
          都是藝術品
        </>
      ),
    },
    {
      text: '戶型簡介',
      title: (
        <>
          每個生活，
          <br />
          皆為現代鉅作
        </>
      ),
    },
    {
      text: '住宅設施',
      title: (
        <>
          <span>Gryphon House</span>
          <br />
          尊爵生活
        </>
      ),
    },
    {
      text: '聯繫我們',
      title: (
        <>
          我們很高興
          <br />
          能為您服務
        </>
      ),
    },
    {
      text: '註冊/登記',
      title: <>立即登記</>,
    },
  ],
  register: '立即登記',
  home: {
    hero: {
      title: (
        <>
          在林畔
          <br />
          見未來
        </>
      ),
    },
    intro: {
      subTitle: '即將到來',
      title: (
        <>
          乘著<span>Gryphon</span>的羽翼， <br />
          蜚聲國際的精緻生活理念
          <br />
          降臨於<span>Kerrisdale</span>。
        </>
      ),
      text:
        '在世界各大頂級都市 ，公寓住宅儼然成為一種高雅的藝術形式。如今，溫哥華亦躋身此陣營，帶來集現代創新與永恒典雅於一體的世界級住宅。在這個富麗堂皇、安靜又顯赫的溫西社區，在這個位於林蔭大道之端又與城市緊密相連的地方，一種富於人文薈萃的都市生活蔚然成風，並欣欣向榮地朝著鼎盛之態發展。',
    },
    linkBlock: {
      subtitle: '企業哲學',
      title: <>藝術，扎根於此</>,
      body:
        '在這個精挑細選的地段，Gryphon將過去25年從全球頂級建築中汲取的靈感與藝術家主導的專業設計融於一體，打造了輝煌的建築傑作。',
      button: '了解詳情',
    },
    contentBlock: [
      {
        subtitle: '完美呈現',
        title: (
          <h2>
            匠心獨具
            <br />
            大師之作
          </h2>
        ),
        body: (
          <>
            Gryphon
            House是遍訪世界的最終成果，它是對全球頂級都市建築進行了全面考察與對最佳生活方式做了深思熟慮之後誕生的。
          </>
        ),
        images: ['home/gryphon-house-storefront.jpg'],
        button: {
          label: '了解詳情',
          path: '/creation',
        },
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '都市文化的新地標',
        title: (
          <h2>
            <span>Kerrisdale</span>
            <br />
            的永恆傳說
          </h2>
        ),
        body: (
          <>
            古老的參天橡樹，歷史悠久的深宅大院，魅力奢华的尊貴社區，首屈一指的教育資源，還有那迷人的、變得越發時尚現代的林蔭大道。
          </>
        ),
        images: [
          'home/wall-of-flowers.jpg',
          'home/dress-shoes.jpg',
          'home/thomas-hobbs-florist.jpg',
          'home/afternoon-tea.jpg',
        ],
        button: {
          label: '了解詳情',
          path: '/location',
        },
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
    ],
  },
  philosophy: {
    subheader: '企業哲學',
    header: (
      <>
        藝術，
        <br />
        扎根於此
      </>
    ),
    art: {
      subTitle: '藝術存在於每一天',
      title: (
        <>
          為了使藝術在塑造社區的方式上產生重大影響，我們必須重新思考它在房屋建造中的作用。
        </>
      ),
      text: (
        <>
          Gryphon創建了<strong>三個部門</strong>
          ，以深化我們在安居藝術上的探索，令您盡情沉浸於藝術世界，以全世界藝術家和思想家的眼光和見解開拓您的視野與思想，並為您精心打造每天完美的居住感受。
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
          匠心獨具
          <br />
          大師之作
        </>
      ),
      body:
        '憑藉對全球頂級建築的借鑒，以及與藝術家在安居藝術方面的通力合作，Gryphon House 為Kerrisdale的盛名再創輝煌。',
      button: '了解詳情',
    },
    contentBlock: [
      {
        title: (
          <h3>
            <span>Gryphon</span>
            <br />
            的企業哲學
          </h3>
        ),
        body: (
          <>
            我們的故事起源於藝術。在過去的25年中，我們的母公司精銳集團改變了我們對建築的看法。
            <br />
            <br />
            精銳將藝術性貫徹於建築的方方面面，用創造性的技巧呈現了真正的奢華，以非凡的方式改變了台灣的天際線和生活方式。藝術家被邀請來主導創意過程，高樓大廈拔地而起，成爲一種新的藝術形式。
          </>
        ),
        images: [
          'philosophy/wooden-thin-panels.jpg',
          'philosophy/jinray-eurostar.jpg',
          'philosophy/jinray-artfair.jpg',
          'philosophy/jinray-resort.jpg',
        ],
        settings: {
          template: 2,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            <span>Gryphon</span>的銳變
          </h3>
        ),
        body: (
          <>
            勇猛強健的獅子是精銳的象征，而當它飛越全球，在一塊美好的新大陸續寫傳奇時，獅子變身成希臘神話中半獅半鷲的獅鷲，它代表了精銳的下一代——Gryphon
            Development。它青出於藍，既有著獅子的強悍，又有著鷲的敏銳和高遠的視角。Gryphon
            Development將這種具有全球視角的對美好生活與豐富文化的最佳註解帶到世界上的頂級街區——紐約的上東區，倫敦的Chelsea和Mayfair。還有，本地的Kerrisdale。
          </>
        ),
        images: [
          'philosophy/wooden-wing-panels.jpg',
          'philosophy/westbury-living.jpg',
          'philosophy/musee-sculpture-lady.jpg',
          'philosophy/westbury-rooftop.jpg',
        ],
        // video: {
        //   label: 'Watch Corporate Video',
        //   file: '',
        // },
        settings: {
          template: 3,
          rowReverse: false,
        },
      },
    ],
    cards: [
      {
        logo: 'logo-atelier-white.png',
        logoSize: 2,
        body:
          'Gryphon藝創工坊是以藝術家爲主導的一項合作，以藝術的方式進行房地産開發。我們廣泛地與各類世界級藝術家合作，利用他們強大的創意潛能和獨特的視角去打造新的生活藝術形式。',
        background: 'atelier-background.jpg',
      },
      {
        logo: 'logo-royal-curator-white.png',
        logoSize: 0,
        body:
          '我們針對安居藝術推出的最新舉措，由忠誠的專業人士為您的各種生活所需提供無微不至的服務，貢獻每個人都最想希望得到的：時間。',
        background: 'curator-background.jpg',
      },
      {
        logo: 'logo-musee-white.png',
        logoSize: 1,
        body: '為現代藝術家提供的展示平台，陳列著他們各自傑出的作品。',
        background: 'musee-background.jpg',
      },
    ],
  },
  creation: {
    subheader: '完美呈現',
    header: (
      <>
        匠心獨具
        <br />
        大師之作
      </>
    ),
    atelier: {
      subTitle: '藝創工坊',
      title: (
        <>
          我們廣泛地與各類世界級藝術家合作，由他們強大的創意潛能和獨特的視角去打造新的生活藝術形式。
        </>
      ),
      block1: {
        title: (
          <>
            <span>Yamamoto</span>
            <br />
            建築事務所
          </>
        ),
        text:
          '傑出的建築師和素描畫家（Taizo Yamamoto）將他的藝術眼光帶入Gryphon Atelier，使藝術與設計融入我們的思想、視野與城市的天際線。',
      },
      block2: {
        title: (
          <>
            <span>False Creek</span>
            <br />
            設計集團
          </>
        ),

        text:
          '著名的溫哥華設計公司——False Creek設計集團擁有近三十年的創新及設計經驗，為Gryphon House賦予了沉靜而輝煌的光彩。',
      },
    },
    collage: {
      title: (
        <>
          大自然的藝術
          <br />
          億萬的年沈淀
        </>
      ),
      text:
        '英式風格的歐式萊姆石外牆覆面，為Kerrisdale的未來營造了一種世界性的精緻感。這種傑出的石材因其在世界古蹟中的應用而聞名，包括西敏寺、白金漢宮和聖保羅大教堂。歷史性的優雅與標誌性的存在相匹配，就在Kerrisdale。',
    },
    contentBlock: [
      {
        title: <h3>全球視野</h3>,
        body: (
          <>
            從台灣母公司精銳建設世界一流的創新技巧，到Gryphon藝術工作室獨一無二的合作方式，再到我們走遍全球追尋靈感新形式，Gryphon一直在探索最出色的藝術、建築與生活方式，用以打造具有全球視野的生活，就在Kerrisdale。
          </>
        ),
        images: [
          'creation/west-blvd-aerial.jpg',
          'creation/paris-aerial.jpg',
          'creation/london-apartment-facade.jpg',
          'creation/london-bank-district.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        subtitle: '建築風格',
        title: (
          <h3>
            每戶住宅
            <br /> 都是藝術品
          </h3>
        ),
        body: (
          <>
            從倫敦那些時尚與傳統相結合的最佳典範汲取靈感，Gryphon
            House為Kerrisdale帶來了完美的變化。
            <br />
            <br />
            Taizo Yamamoto為Gryphon
            House設計了古典的建築風格，與周邊古色古香的社區環境相輔相成，它同時也是描繪社區未來的一種全新視角，為城市增添的一個新的藝術形式。
          </>
        ),
        images: ['creation/taizo-yamamoto-architecture-plans.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '室內品味',
        title: (
          <h3>
            每個生活
            <br />
            都是現代鉅作
          </h3>
        ),
        body: (
          <>
            憑借False
            Creek設計集團創造性的細節、高超的設計以及在世界任何地方都罕見的精湛工藝，Gryphon
            House成爲Kerrisdale尊貴名望與輝煌未來的傑出代表。
          </>
        ),
        images: [
          'creation/coffee-tables.jpg',
          'creation/fcdg-tim.jpg',
          'creation/fcdg-dale.jpg',
        ],
        settings: {
          template: 5,
          rowReverse: true,
        },
      },
    ],
    linkBlock: {
      title: (
        <>
          <span>Kerrisdale</span>
          <br />
          的永恆傳說
        </>
      ),
      text:
        '歷史悠久，尊貴永恆。安居的藝術要從講究位置開始，在溫哥華聲名顯赫的溫西地區之中心地段，這一集全球建築之精髓的新典範為這裡古老而尊貴的生活再添新的高雅。',
      buttonLabel: '了解詳情',
    },
  },
  location: {
    subheader: '地理位置',
    header: (
      <>
        <span>Kerrisdale</span>
        <br />
        的永恆傳說
      </>
    ),
    map: {
      title: <>溫哥華西區</>,
      text:
        '為追求生活美學的您精心選擇。便捷的生活享受，寧謐的休憩環境，居住的藝術。',
      categories: [
        {
          title: '商業購物大道',
          points: [
            {
              text: 'Kerrisdale商店街',
              color: '#ed6f33',
            },
            {
              text: 'West Boulevard購物大道',
              color: '#3e63ae',
            },
          ],
        },
        {
          title: '休閒 & 娛樂',
          points: [
            {
              text: '高爾夫球天堂',
              letter: 'A',
            },
            {
              text: 'Southlands馬術俱樂部',
              letter: 'B',
            },
            {
              text: 'Arbutus私人會所',
              letter: 'C',
            },
            {
              text: 'Kerrisdale冰球場',
              letter: 'D',
            },
            {
              text: 'Kerrisdale社區中心',
              letter: 'E',
            },
          ],
        },
        {
          title: '綠地 & 新鮮空氣',
          points: [
            {
              text: 'Elm公園',
              letter: 'A',
            },
            {
              text: 'Maple Grove公園',
              letter: 'B',
            },
            {
              text: 'Kerrisdale公園',
              letter: 'C',
            },
            {
              text: 'Arbutus綠徑',
              color: '#407d12',
            },
          ],
        },
        {
          title: '世界級名校',
          points: [
            {
              text: 'Maple Grove & Magee中學',
              letter: 'A',
            },
            {
              text: 'Point Grey中學',
              letter: 'B',
            },
            {
              text: 'Crofton House私立女校',
              letter: 'C',
            },
            {
              text: 'St. George’s小學',
              letter: 'D',
            },
            {
              text: 'St. George’s中學',
              letter: 'E',
            },
            {
              text: '溫哥華學院',
              letter: 'F',
            },
            {
              text: '卑詩大學',
              letter: 'G',
            },
          ],
        },
      ],
    },
    contentBlock: [
      {
        title: <h3>這裡安居的理想之地，這裡有著古老而尊貴的生活。</h3>,
        body: (
          <>
            宏偉的建築，連綿的草坪，和家族世代的繁衍生息，構成了Kerrisdale那顯而易見的尊榮氣質。
            在聲名顯赫的溫西地區，這是一個珍貴難得的位置。本地最好的學校聚集於此。咖啡館和餐館，服裝店和書店，共同在這個城市的核心之地營造了一個高雅文化的小世界——它迎合了都市生活未來發展的各種需求。
          </>
        ),
        images: [
          'location/walking-with-horse.jpg',
          'home/thomas-hobbs-florist.jpg',
          'location/kerrisdale-clock.jpg',
          'location/bike-at-storefront.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        title: <h3>首屈一指的教育資源</h3>,
        body: (
          <>
            從最佳私校到本城領先的公校，再到一所世界頂尖的大學，這個城市最出色的教育機構都集中在此。這是一個構建完美生活，打造光明未來的理想之地。
          </>
        ),
        images: ['location/school-stone-facade.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        title: <h2>世界一流的景觀長廊</h2>,
        subtitle: 'the arbutus greenway',
        body: (
          <>
            从Gryphon House走上West
            Boulevard，這條迷人的林蔭路成為世界最長的綠園道公園之一，日新月異的改變使它有如一條美麗的長廊，遍佈著藝術裝置、咖啡館和小店鋪、文化場所以及令人歎為觀止的園林景觀。
            <br />
            <br />
            這條著名的林蔭路形成了絕佳的公共步道，媲美世界繁華大都市中心區那些最知名的所在——紐約堪稱典範的Highline公園，巴塞羅娜那充滿活力的Las
            Ramblas，巴黎市中心令人回味的Avenue
            Montaigne。而這條大道，就在你的家門口。
          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'location/newyork-highline.jpg',
          'location/barcelona-las-ramblas.jpg',
          'location/paris-champs-elysees.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
    ],
    callouts: [
      {
        text: '堪稱典範的紐約Highline公園',
      },
      {
        text: '巴黎市中心令人回味的Avenue Montaigne',
      },
      {
        text: '巴塞羅娜那充滿活力的Las Ramblas',
      },
      {
        text: '最新型態的綠園大道，就在Gryphon House',
      },
    ],
    linkBlock: {
      title: (
        <>
          每個生活，
          <br />
          皆為現代鉅作
        </>
      ),
      body:
        '勇猛強健的獅子是台灣精銳建設的象征，當它飛越全球，在一塊美好的新大陸變身成希臘神話中半獅半鷲的獅鷲—Gryphon Development。Gryphon Development將這種具有全球視角的對美好生活與豐富文化的最佳註解從世界上的頂級街區—紐約的Upper East Side，倫敦的Chelsea和Mayfair, 一一帶到溫哥華的Kerrisdale 。',
      button: '了解詳情',
    },
  },
  contact: {
    subheader: '聯繫我們',
    header: (
      <>
        我們很高興
        <br />
        能為您服務
      </>
    ),
    directions: '開啟地圖',
    vancouver: {
      title: '溫哥華展銷中心',
    },
    hk: {
      title: '香港展銷中心',
    },
  },
  footer: {
    register: {
      subheader: '註冊/登記',
      header: (
        <>
          探索<span>Gryphon House</span>的生活美學。
        </>
      ),
      button: '立即登記',
    },
    contact: {
      contact: '聯繫我們',
      social: 'social',
      disclaimer:
        '此內容僅供信息參考，並非銷售合同。銷售合同須與披露聲明共同提供。如有錯漏，不在此限。以上照片、圖片僅作為說明，開發商保留隨時更改面積、價格、設計和（或者）裝潢及設施的權利。',
    },
  },
  registerPage: {
    intro: {
      subTitle: '立即登記',
      title: (
        <>
          探索<span>Gryphon House</span>的生活美學。
        </>
      ),
    },
    firstName: '名字*',
    lastName: '姓氏*',
    email: '電郵*',
    phone: '電話號碼*',
    postal: '郵編*',
    howHeard: '您是如何知道我們的？（下拉）',
    howHeardOptions: [
      {
        label: '標牌',
        value: 'Signage',
      },
      {
        label: 'Walk/Drive-by',
        value: 'Walk/Drive-by',
      },
      {
        label: '網上廣告',
        value: 'Online Advertising',
      },
      {
        label: '報紙或雜誌',
        value: 'Print Advertising',
      },
      {
        label: 'Family/Friend',
        value: 'Family/Friend',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    realtor: '您是地產經紀嗎？*',
    realtorOptions: [
      {
        label: '是',
        value: 'true',
      },
      {
        label: '否',
        value: 'false',
      },
    ],
    typeHome: '您的房型需求？',
    typeHomeOptions: [
      {
        label: '2 Bedroom',
        value: '2 Bedroom',
      },
      {
        label: '2 Bedroom + Den',
        value: '2 Bedroom + Den',
      },
      {
        label: '3 Bedroom',
        value: '3 Bedroom',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
      {
        label: 'Penthouse',
        value: 'Penthouse',
      },
      {
        label: 'Retail (CRU)',
        value: 'Retail (CRU)',
      },
    ],
    checkbox:
      '我特此同意接收來自Gryphon Development以及其現在和未來附屬企業和子公司的郵件。郵件可包括現在和未來樓盤的價格、建築平面圖、銷售情況、活動邀請、通知、企業簡報和其他信息。我知道我可以隨時停止接收廣告電郵。',
    submit: '提交',
    register: '立即登記',
    thankYou: '謝謝您的註冊。我們的銷售代表將很快與您聯繫。',
    backToHome: '返回首頁',
  },
  thankyou: {
    title: <>謝謝您的註冊。</>,
    text: (
      <>
        您已經成功登記，我們將會有專人聯絡閣下。
        <br />
        <br />
        如欲網上預約親臨展銷場地，請按以下連結。
      </>
    ),
    button: '返回首頁',
    appButton: '網上預約',
  },
  residences: {
    hero: {
      subTitle: '戶型簡介',
      title: (
        <>
          每個生活，
          <br />
          皆為現代鉅作
        </>
      ),
    },
    plans: {
      title: '精選戶型圖',
      filters: [
        {
          mobile: '2 房',
          text: '2 房',
          plans: ['B5c', 'B8', 'C1b', 'C2a'],
        },
        {
          mobile: '3 房',
          text: '3 房',
          plans: ['B1a', '507+508'],
        },
        {
          mobile: '聯排別墅',
          text: '聯排別墅',
          plans: ['TH4'],
        },
      ],
      button: '下載精選戶型圖',
    },
    kitchen: {
      subTitle: '廚房',
      title: <>精選電器 優質石材</>,
      text:
        '精選歐洲品牌電器Gaggenau, Milano Fulgar等, 檯面和擋板選用獨特石材Caesarstone, 防火防菌。',
    },
    ensuites: {
      subTitle: '套房衛浴空間',
      title: <>英式典雅設計簡約高貴</>,
      text:
        '私人衛浴空間簡單優雅, 四面牆身以瓷磚包裹，塑造甯靜樸素的空間洗滌身心。英式精致歷久常新，配合現代設計富麗堂皇。',
    },
    features: '下載戶型配套',
    linkBlock: {
      title: (
        <>
          <span>Gryphon House</span>
          <br />
          尊爵生活
        </>
      ),
      text:
        '在Gryphon House的優美生活，由各類服務、休閒及設施所組成，透過這些簡單、健康且充滿喜悅的微小細節，您將能充分享受生活中的每一個片刻。',
      button: '瀏覽生活設施',
    },
  },
  amenities: {
    hero: {
      header: (
        <>
          <span>
            Gryphon
            <br />
            House
          </span>
          <br />
          尊爵生活
        </>
      ),
      subheader: '生活設施',
    },
    contentBlocks: [
      {
        title: (
          <h3>
            打造
            <br />
            生活美學
          </h3>
        ),
        body: (
          <>
            生活，是由各種細節所組成。我們難得有機會，能選擇這些組成生活的微小細節。{' '}
            <br />
            <br />
            在Gryphon House的優雅生活，由各類服務、休閒及設施所組成，
            透過這些簡單、健康且充滿喜悅的微小細節，您將能充分享受
            生活中的每一個片刻。
          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'amenities/yoga-mat.jpg',
          'amenities/preparing-drink.jpg',
          'amenities/butler-with-champagne.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            讓您的生活
            <br />
            更趨完美
          </h3>
        ),
        body: (
          <>
            專為Gryphon Development業主而設，Royal
            Curator的宗旨在於進一步體現生活藝術。
            透過各種高度個人化的服務，為您提高生活品質，讓生活更加愜意，也為您省下更多時間，
            以便享受生活中的一切美好。
          </>
        ),
        images: [
          'amenities/woman-beside-pool.jpg',
          'amenities/butler-with-ipad.jpg',
          'amenities/blue-suit.jpg',
          'amenities/butler-opening-cardoor.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: false,
        },
        buttonLabel: '瀏覽網站',
      },
    ],
    callouts: [
      {
        title: '入口禮賓大廳',
        text:
          '您專屬的Gryphon House迎賓處，24小時全年無休，提供包含行李搬運、包裹寄送及低溫冷藏等各種貼心服務。任何需求，儘管吩咐。',
      },
      {
        title: '舒壓室',
        text: '水療、按摩、紅外線桑拿、個人及身心靈呵護保養。',
      },
      {
        title: '健身房',
        text:
          '寬敞的健身中心，在兩層樓的空間中，配備最新的Technogym器材及飛輪設備，讓您在自家社區就能每天鍛鍊。',
      },
      {
        title: '私人宴會廳',
        text:
          '豪華的宴會廳及侍酒吧台，以各種貼心細節，讓您享受自我時光、品味卓越的生活藝術。',
      },
      {
        title: '商務中心',
        text:
          '為您的住宅添加額外功能。在面對事業挑戰時，這個能令人專注的空間，將讓您的工作效率如虎添翼。',
      },
      {
        title: '停車場門廳',
        text: '這個經常被使用的空間體現低調奢華，定義非凡且典雅的生活藝術。',
      },
    ],
    titleBlock: {
      subtitle: '皇家會館',
      title: <>您的一切需求皆被滿足，為您節省更多寶貴光陰。</>,
    },
    linkBlock: {
      title: (
        <>
          我們的故事
          <br />
          源於藝術
        </>
      ),
      text:
        '與半獅半鷲的的神話傳奇齊名，Gryphon Development將這種具有全球視角、對美好生活與豐富文化的最佳註解，帶到世界各地的頂級街區。',
      button: '企業哲學',
    },
  },
  design: {
    hero: {
      header: (
        <>
          每戶住宅
          <br />
          都是藝術品
        </>
      ),
      subheader: '精美設計',
    },
    downloadFeatures: '下載詳細特色介紹',
    contentBlocks: [
      {
        title: (
          <h3>
            <span className="regular-heading">Modern</span>{' '}
            <span>Grandeur.</span>
            <br />
            <span className="regular-heading">Greenside</span>{' '}
            <span>Manor.</span>
          </h3>
        ),
        text: (
          <>
            以倫敦的當代傳統主義為靈感，Gryphon
            House在Kerrisdale打造了經典又現代的舒適豪邸，以創意及貼心設計，完美體現為城市生活而精心設計的生活空間。
          </>
        ),
      },
      {
        title: (
          <h3>
            靈感源於
            <br />
            倫敦鉅作
          </h3>
        ),
        text: (
          <>
            在尊貴地段中，扮演著舉足輕重的角色。Gryphon
            House的設計，正如同英國倫敦那些享負盛名的傑出酒店，在現代及傳統之間，取得取得絕佳的完美平衡。
          </>
        ),
      },
    ],
    callouts: [
      {
        title: '當代傳統主義',
        text:
          '結合英式現代傳統主義的簡潔及典雅風格，垂直走向的窗戶及建築的水平線條相得益彰，天然石材紋理也與現代金屬質感完美融合、相輔相成。',
      },
      {
        title: '電器',
        text: (
          <>
            自1683以來，有遠見的經典設計、對品質的高度要求，以及卓越的精湛工藝，
            都是 <span>Gaggenau Hausgeräte</span> 在全球被受肯定的關鍵。
            這些與眾不同的特質，更讓每個廚房成為了 生活中不可或缺的藝術大作。{' '}
          </>
        ),
      },
      {
        title: '石英石檯面',
        text: (
          <>
            亮眼且令人讚嘆的石英石檯面，是這個空間的奪目焦點。以經典的石材，打造出如同珠寶般之耀眼現代感。
          </>
        ),
      },
      {
        title: '臥房',
        text: (
          <>
            設有封閉式陽台空間的私人殿堂*，為了保障隱私、安寧且確保能得到充分休息，
            也裝設了三層玻璃窗戶。
            <br />
            <span className="small">* 部分單位</span>
          </>
        ),
      },
      {
        title: '衣櫃',
        text: <>優美的定製衣櫥，以簡單且有效率的方式，創造高效率的收納空間。</>,
      },
      {
        title: '露台',
        text:
          '每間住宅的特大露台也预先安装了瓦斯接口，都能迎來充足的自然光與新鮮空氣。住戶專屬的空中花園，除了能飽覽北岸山脈與太平洋的美景之外，也預先安裝了瓦斯爐台，讓您在一覽美景的同時，也能享用美味佳餚。',
      },
      {
        title: '空中花園',
        text:
          '這個寬敞又舒適的頂樓花園，是您在傍晚時分享受夕陽餘溫及觀賞美景的絕佳去處。',
      },
    ],
    titleBlocks: [
      {
        title: (
          <>
            大師級經典工藝，
            <br />
            恆久流傳
          </>
        ),
        subtitle: '歷史悠久的經典質材',
        text: (
          <>
            Gryphon
            House採用獨特的歐式萊姆石打造建築外牆，將為Kerrisdale帶來全新風貌。這款經典建材也被用於西敏寺，以及聖保羅大教堂等知名地標，不僅具有悠久歷史，更能永世傳承。
          </>
        ),
      },
      {
        title: <>以智能設計之美，巧妙勾勒出 卓越居家生活。</>,
        subtitle: '歡迎返家',
        text:
          '這個融合現代與經典的生活空間，以令人倍感舒心的木質工藝，在門廳處歡迎您返回家中。再走幾步，便能體會到由寬木地板及各種當代元素所共同呈現的典雅氛圍。',
      },
    ],
    collage: {
      title: (
        <>
          與凡爾賽宮同等的石雕工藝在
          <span className="regular-heading">Kerrisdale</span>完美呈現。
        </>
      ),
    },
    imageCompares: {
      calloutTap: '(請點擊圖像以查看其他配色方案)',
      living: {
        title: '客廳',
        text:
          '帶有簡潔線條的寬敞空間，對於各種細節面面俱到的要求，讓您能在此盡情揮灑個人色彩。',
      },
      kitchen: {
        title: '廚房',
        text: (
          <>
            以簡潔的線條及令人感到溫馨的木質材料，打造經典永恆的體現。
            精美的定製款歐式櫥櫃更展現了 工匠的精湛手藝。
          </>
        ),
      },
      bathroom: {
        title: '衛浴間',
        text: (
          <>
            落地式大型牆面磁磚， 搭配現代化的<span>Grohe</span>{' '}
            配件，並以精心規劃的照明， 令豪華的當代設計更上一層。
          </>
        ),
      },
    },
    linkBlock: {
      title: (
        <>
          每個生活，
          <br />
          皆是當代鉅作
        </>
      ),
      body:
        '開放且寬敞的戶型設計，專為各種生活精心打造。每一間住宅鉅細彌遺的規劃，都是為了讓您的生活樂章更加和諧。',
      button: '瀏覽戶型',
    },
  },
  appointment: {
    menu: '網上預約',
    subtitle: '網上預約',
    title: (
      <>
        線上預約，專人為您講解更多<span>Gryphon House</span> 詳情。
      </>
    ),
    office: '展銷地址:',
    text: '銅鑼灣利園三期4樓',
  },
};
