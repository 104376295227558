import React, { useEffect, useRef } from 'react';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpen } from 'src/redux/menu';

import Header from './elements/Header';
import Overlay from './elements/Overlay';

const Menu = () => {
  const isOpen = useSelector((state) => state.menu.isOpen);

  const dispatch = useDispatch();

  const $overlay = useRef();

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const handleMenu = () => {
    if (isOpen) {
      enableBodyScroll($overlay.current);
    } else {
      disableBodyScroll($overlay.current);
    }
    dispatch(setIsOpen(!isOpen));
  };

  const handleClose = () => {
    enableBodyScroll($overlay.current);
    dispatch(setIsOpen(false));
  };

  return (
    <>
      <Header
        handleMenu={handleMenu}
        isMenuOpen={isOpen}
        handleClose={handleClose}
      />
      <Overlay active={isOpen} closeMenu={handleClose} overlayRef={$overlay} />
    </>
  );
};

export default Menu;
