import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';
import Illustration from 'components/Illustration';
import TitleBlock from 'components/TitleBlock';
import LinkBlock from 'components/LinkBlock';
import Map from './sections/Map';
import Callouts from './sections/Callouts';

import vw from 'src/styles/utils';

const Location = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page>
              <Hero
                subheader={t.location.subheader}
                header={t.location.header}
                video="https://player.vimeo.com/external/492212540.hd.mp4?s=b7dca745065337edb4bdedf9617c33c178349c8f&amp;profile_id=174"
                popupVideo="https://player.vimeo.com/external/488801969.hd.mp4?s=f59fe31fd654bc28f70e29ecc46f7624aed48f81&amp;profile_id=175"
              >
                {/* <HeroImage
                  src={require('src/assets/images/location/illustration-kerrisdale-stripped.png')}
                  alt=""
                /> */}
              </Hero>
              <Space />
              <ContentBlock
                title={t.location.contentBlock[0].title}
                body={t.location.contentBlock[0].body}
                images={t.location.contentBlock[0].images}
                settings={t.location.contentBlock[0].settings}
              />
              <Map />
              <ContentBlock
                title={t.location.contentBlock[1].title}
                body={t.location.contentBlock[1].body}
                images={t.location.contentBlock[1].images}
                settings={t.location.contentBlock[1].settings}
              >
                <Illustration
                  mobile={{
                    width: 263.5,
                    height: 150,
                    top: 580,
                    left: 112,
                  }}
                  tablet={{
                    width: 527,
                    height: 300,
                    top: 980,
                    left: 345,
                  }}
                  desktop={{
                    width: 527,
                    height: 300,
                    top: 360,
                    left: 938,
                  }}
                  distance={-120}
                  image="location/illustration-ubc.png"
                />
              </ContentBlock>
              <StyledTitleBlock
                title={t.location.contentBlock[2].title}
                subTitle={t.location.contentBlock[2].subtitle}
                text={t.location.contentBlock[2].body}
              >
                <Illustration
                  mobile={{
                    width: 329,
                    height: 220,
                    top: 250,
                    left: -60,
                  }}
                  tablet={{
                    width: 658,
                    height: 440,
                    top: 0,
                    left: -100,
                  }}
                  desktop={{
                    width: 658,
                    height: 440,
                    top: -40,
                    left: 0,
                  }}
                  distance={30}
                  image="location/illustration-arbutus-greenway.png"
                />
              </StyledTitleBlock>
              <Callouts />
              <Space large />
              <LinkBlock
                title={t.location.linkBlock.title}
                body={t.location.linkBlock.body}
                buttonLabel={t.location.linkBlock.button}
                bgImageMobile="residences/kitchen-scheme-dark.jpeg"
                bgImageDesktop="residences/kitchen-scheme-dark.jpeg"
                url="/design"
                noLogo
                small
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Location;

const Root = styled.div``;

// const HeroImage = styled.img`
//   position: absolute;
//   top: 42.5%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: auto;
//   height: 100%;
//   ${vw('max-width', 232, 463)}
//   ${vw('max-height', 364.78, 728)}
//   @media ${media.tablet} {
//     top: 49%;
//   }
// `;

const StyledTitleBlock = styled(TitleBlock)`
  br {
    display: block !important;
  }
`;

const Space = styled.div`
  ${(props) =>
    props.large ? vw('margin-top', 80, 160, 160) : vw('margin-top', 80)}
`;
