import { css } from 'styled-components';
import vw from 'src/styles/utils';
import theme from './theme';

export const Template1 = css`
  position: relative;
  ${vw('width', 288, 598)}
  ${vw('height', 327, 680)}
  .parallax-inner, img {
    height: 100%;
    object-fit: cover;
  }
  .parallax-outer {
    position: absolute;
    &:nth-of-type(1) {
      ${vw('width', 202, 420)}
      ${vw('height', 299, 620)}
      ${vw('top', 0, 0)}
      ${vw('left', 39, 80)}
    }
    &:nth-of-type(2) {
      ${vw('width', 82, 170)}
      ${vw('height', 120, 250)}
      ${vw('top', 19, 40)}
      ${vw('left', 206, 428)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(3) {
      ${vw('width', 125, 260)}
      ${vw('height', 183, 380)}
      ${vw('top', 39, 80)}
      ${vw('left', 0, 0)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(4) {
      ${vw('width', 106, 220)}
      ${vw('height', 144, 300)}
      ${vw('top', 183, 380)}
      ${vw('left', 163, 338)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
  }
`;

export const Template2 = css`
  position: relative;
  ${vw('width', 288, 598)}
  ${vw('height', 328, 680)}
  .parallax-outer {
    position: absolute;
    &:nth-of-type(1) {
      ${vw('width', 202, 420)}
      ${vw('height', 299, 620)}
      ${vw('top', 29, 60)}
      ${vw('left', 39, 80)}
    }
    &:nth-of-type(2) {
      ${vw('width', 96, 200)}
      ${vw('height', 143, 296)}
      ${vw('top', 0, 0)}
      ${vw('left', 175, 364)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(3) {
      ${vw('width', 125, 260)}
      ${vw('height', 183, 380)}
      ${vw('top', 67, 140)}
      ${vw('left', 0, 0)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(4) {
      ${vw('width', 135, 280)}
      ${vw('height', 87, 180)}
      ${vw('top', 222, 460)}
      ${vw('left', 153, 318)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
  }
`;

export const Template3 = css`
  position: relative;
  ${vw('width', 288, 598)}
  ${vw('height', 327, 680)}
  .parallax-outer {
    position: absolute;
    &:nth-of-type(1) {
      ${vw('width', 169, 350)}
      ${vw('height', 241, 500)}
      ${vw('top', 53, 110)}
      ${vw('left', 61, 126)}
    }
    &:nth-of-type(2) {
      ${vw('width', 104, 215)}
      ${vw('height', 135, 280)}
      ${vw('top', 0, 0)}
      ${vw('left', 35, 72)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(3) {
      ${vw('width', 111, 230)}
      ${vw('height', 166, 345)}
      ${vw('top', 91, 188)}
      ${vw('left', 177, 368)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(4) {
      ${vw('width', 144, 300)}
      ${vw('height', 96, 200)}
      ${vw('top', 231, 480)}
      ${vw('left', 0, 0)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
  }
`;

export const Template4 = css`
  position: relative;
  ${vw('width', 288, 598)}
  ${vw('height', 327, 680)}
  .parallax-outer {
    position: absolute;
    img {
      width: 100%;
    }
    &:nth-of-type(1) {
      ${vw('width', 202, 420)}
      ${vw('height', 299, 620)}
      ${vw('top', 0, 0)}
      ${vw('left', 50, 103)}
    }
    &:nth-of-type(2) {
      ${vw('width', 106, 220)}
      ${vw('height', 144, 300)}
      ${vw('top', 24, 50)}
      ${vw('left', 0, 0)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(3) {
      ${vw('width', 87, 180)}
      ${vw('height', 125, 260)}
      ${vw('top', 67, 188)}
      ${vw('left', 202, 418)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(4) {
      ${vw('width', 135, 300)}
      ${vw('height', 96, 200)}
      ${vw('top', 231, 480)}
      ${vw('left', 21, 42)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
  }
`;

export const Template5 = css`
  position: relative;
  ${vw('width', 288, 598)}
  ${vw('height', 289, 600)}
  .parallax-outer {
    position: absolute;
    &:nth-of-type(1) {
      ${vw('width', 188, 391)}
      ${vw('height', 260, 540)}
      ${vw('top', 29, 60)}
      ${vw('left', 50, 103)}
    }
    &:nth-of-type(2) {
      ${vw('width', 111, 230)}
      ${vw('height', 154, 320)}
      ${vw('top', 0, 0)}
      ${vw('left', 177, 368)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
    &:nth-of-type(3) {
      ${vw('width', 111, 230)}
      ${vw('height', 154, 320)}
      ${vw('top', 102, 211)}
      ${vw('left', 0, 0)}
      img {
        box-shadow: ${theme.shadow};
      }
    }
  }
`;

export const Template6 = css`
  position: relative;
  ${vw('width', 288, 495)}
  ${vw('height', 349, 600)}

  .parallax-outer {
    position: absolute;
    ${vw('width', 288, 495)}
    ${vw('height', 349, 600)}
    ${vw('top', 0, 0)}
    ${vw('left', 0, 0)}
  }

  img {
    box-shadow: ${theme.shadow};
    ${vw('width', 288, 495)}
    ${vw('height', 349, 600)}
  }
`;
