import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import GradientText from 'components/typography/GradientText';
import GoldTitle from 'components/typography/GoldTitle';
import Intl from 'components/Intl';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/menu';

const HoverOverlay = (props) => {
  const { isHero, closeMenu, active } = props;
  const [activeHover, setActiveHover] = useState(null);

  const location = useLocation();

  const handleMouseOver = (index) => {
    setActiveHover(index);
  };

  const handleMouseOut = () => setActiveHover(null);

  const handleIsHome = () => {
    let path = location.pathname;
    path = path.split('/').join('');
    if (path === '' || path === 'sc' || path === 'tc') {
      return true;
    } else {
      return false;
    }
  };

  const handleIndexForAnimation = (index) => {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 1;
      case 2:
        return 0;
      default:
        return 0;
    }
  };

  const handleCloseMenu = () => {
    closeMenu();
    handleMouseOut();
  };

  const handleFirstLinks = (t, url) => {
    let first3, first3locale;
    first3 = data.slice(1, 4);
    first3locale = t.menu.slice(1, 4);

    return first3.map((item, index) => (
      <StyledLink
        key={item.to + active}
        to={url + item.to}
        className="menu-page light"
        onMouseOver={() =>
          handleMouseOver(
            data.findIndex((dataItem) => dataItem.text === item.text)
          )
        }
        onMouseLeave={handleMouseOut}
        isHero={isHero}
        comingSoon={item.comingSoon}
        onClick={closeMenu ? handleCloseMenu : (f) => f}
        active={
          item.notOnHome ? handleIsHome() : location.pathname.includes(item.to)
        }
        index={handleIndexForAnimation(index)}
      >
        {first3locale[index].text}
      </StyledLink>
    ));
  };

  const handleSecondLinks = (t, url) => {
    let second3, second3locale;
    second3 = data.slice(4, 7);
    second3locale = t.menu.slice(4, 7);

    return second3.map((item, index) => (
      <StyledLink
        key={item.to + active}
        to={url + item.to}
        className={`${item.register ? 'menu-cta' : 'menu-page'} light`}
        onMouseOver={() =>
          handleMouseOver(
            data.findIndex((dataItem) => dataItem.text === item.text)
          )
        }
        onMouseLeave={handleMouseOut}
        onClick={closeMenu && !item.comingSoon ? handleCloseMenu : (f) => f}
        as={item.comingSoon ? 'button' : Link}
        active={location.pathname.includes(item.to)}
        index={index}
        isHero={isHero}
        comingSoon={item.comingSoon}
      >
        {item.register ? (
          <GradientText>{second3locale[index].text}</GradientText>
        ) : (
          second3locale[index].text
        )}
        {item.comingSoon && (
          <p className="menu-comingsoon">
            <GradientText>{t.comingSoon}</GradientText>
          </p>
        )}
      </StyledLink>
    ));
  };

  const handleTopLinks = (t, url) => {
    let last2, last2locale;
    last2 = data.slice(7, 9);
    last2locale = t.menu.slice(7, 9);

    return last2.map((item, index) => (
      <StyledLink
        key={item.to + active}
        to={url + item.to}
        className={`${item.register ? 'menu-cta' : 'menu-page'} light`}
        onMouseOver={() =>
          handleMouseOver(
            data.findIndex((dataItem) => dataItem.text === item.text)
          )
        }
        onMouseLeave={handleMouseOut}
        onClick={closeMenu ? handleCloseMenu : (f) => f}
        active={location.pathname.includes(item.to)}
        index={index}
        isHero={isHero}
        comingSoon={item.comingSoon}
        isTop={true}
      >
        {item.register ? (
          <GradientText>{last2locale[index].text}</GradientText>
        ) : (
          last2locale[index].text
        )}
      </StyledLink>
    ));
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <Appointment
            className="menu-cta"
            href="https://app.acuityscheduling.com/schedule.php?owner=21639809&amp;appointmentType=19435902"
            key={active}
            isTop
            onClick={closeMenu}
            index={2}
            as="a"
            target="_blank"
          >
            <GradientText>{t.appointment.menu}</GradientText>
          </Appointment>
          <TopLinks>{handleTopLinks(t, getLocaleURL())}</TopLinks>
          <Wrapper>
            <Links>{handleFirstLinks(t, getLocaleURL())}</Links>
            <Title key={active}>
              <TitleContainer
                style={{
                  opacity: activeHover === null ? 1 : 0,
                  zIndex: activeHover === null ? -1 : -2,
                }}
              >
                <GoldTitle text={t.home.hero.title} />
              </TitleContainer>
              {data.map((item, index) => (
                <TitleContainer
                  style={{
                    opacity: activeHover === index ? 1 : 0,
                    zIndex: activeHover === index ? -1 : -2,
                  }}
                >
                  <GoldTitle text={t.menu[index].title} />
                </TitleContainer>
              ))}
            </Title>
            <Links>{handleSecondLinks(t, getLocaleURL())}</Links>
          </Wrapper>
          {data.map((item, index) => (
            <Background
              key={index}
              image={item.image}
              style={{
                opacity: activeHover === index ? 1 : 0,
                zIndex: activeHover === index ? -1 : -2,
              }}
            >
              {item.video && <video src={item.video} autoPlay muted loop />}
            </Background>
          ))}
        </Root>
      )}
    </Intl>
  );
};

HoverOverlay.propTypes = {
  isHero: PropTypes.bool,
  closeMenu: PropTypes.func,
};

const FadeIn = keyframes`
  0% {
    opacity: 0;
    filter: blur(40px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
`;

const FadeInCss = css`
  animation-name: ${FadeIn};
  animation-duration: ${({ theme }) => theme.animation.time};
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

const FadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeUpHalf = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 0.5;
    transform: translateY(0);
  }
`;

const FadeUpCss = css`
  animation-name: ${(props) => (props.comingSoon ? FadeUpHalf : FadeUp)};
  animation-duration: 0.6s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: ${(props) =>
    props.isHero ? 0.05 * props.index + 2.5 : 0.05 * props.index + 1.2}s;
`;

const Root = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  width: ${vwDesktop(390)};
`;

const TopLinks = styled.div`
  display: flex;
  position: absolute;
  top: ${vwDesktop(62)};
  right: ${vwDesktop(60)};
`;

const Title = styled.div`
  width: ${vwDesktop(440)};
  height: ${vwDesktop(336)};
  position: relative;
  z-index: 3;
  opacity: 0;
  ${FadeInCss}
`;

const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: ${(props) => props.image && `url(${props.image})`};
  background-size: cover;
  background-position: center;
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  height: fit-content;
  margin-right: ${vwDesktop(32)};
  opacity: 0;
  /* transform: translateY(25%); */
  cursor: pointer;
  ${FadeUpCss}
  &:last-of-type {
    margin-right: 0;
  }
  &:nth-of-type(1) {
    margin-right: ${vwDesktop(32)};
  }
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      92deg,
      #f5d696 26%,
      #f4e4a8 37%,
      #edd397 49%,
      #deb66d 58%,
      #dea960 73%
    );
    position: absolute;
    left: 0;
    top: ${`calc(100% + ${vwDesktop(4)})`};
    opacity: ${(props) => (props.active && !props.isTop ? 1 : 0)};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
  &:hover::after {
    opacity: ${(props) => !props.comingSoon && !props.isTop && 1};
  }
`;

const Appointment = styled(StyledLink)`
  position: absolute;
  top: ${vwDesktop(62)};
  left: ${vwDesktop(60)};
`;

export default HoverOverlay;
