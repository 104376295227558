import React, { useState } from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import TitleBlock from 'components/TitleBlock';
import BackgroundVideo from 'components/layout/BackgroundVideo';
import VideoPopup from 'components/layout/VideoPopup';
import VideoButton from 'components/typography/VideoButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  const [isPopupActive, setIsPopupActive] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupActive(true);
  };

  const handleClosePopup = () => {
    setIsPopupActive(false);
  };

  return (
    <Intl>
      {(t) => (
        <Root>
          <TitleBlock
            subTitle={t.home.intro.subTitle}
            title={t.home.intro.title}
            text={t.home.intro.text}
          />
          <Wrapper>
            <VideoButton
              transparent
              text={t.watchFilm}
              handleClick={handleOpenPopup}
            />
          </Wrapper>
          <BackgroundVideo src="https://player.vimeo.com/external/492212621.hd.mp4?s=679a1379086ad631a366ce786f37b90f193b906a&amp;profile_id=174" />
          <VideoPopup
            active={isPopupActive}
            src="https://player.vimeo.com/external/463210004.hd.mp4?s=f4a20024075ccccfe6a5f55ec7383353330493b3&amp;profile_id=175"
            handleClose={handleClosePopup}
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  padding: ${vwMobile(80)} 0;
  position: relative;
  @media ${media.tablet} {
    padding: ${vwTablet(160)} 0;
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(160)} 0;
  }
`;

const Wrapper = styled.div`
  margin: ${vwMobile(40)} ${vwMobile(16)} 0;
  @media ${media.tablet} {
    margin: ${vwTablet(40)} 0 0 ${vwTablet(278)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(40)} 0 0 ${vwDesktop(659)};
  }
`;

export default Intro;
