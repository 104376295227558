import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';
import GoldTitle from 'components/typography/GoldTitle';
import BackgroundVideo from 'components/layout/BackgroundVideo';
import VideoPopup from 'components/layout/VideoPopup';
import VideoButton from 'components/typography/VideoButton';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const {
    subheader,
    header,
    children,
    video,
    popupVideo,
    videoComingSoon,
  } = props;
  const [popupActive, setPopupActive] = useState(false);

  const handlePopup = () => {
    setPopupActive(!popupActive);
  };

  return (
    <Intl>
      {(t) => (
        <Root>
          <Heading>
            <h2 className="subheader">{subheader}</h2>
            <GoldTitle text={header} />
          </Heading>
          {children}
          <Line />
          {video && <BackgroundVideo src={video} />}
          {(popupVideo || videoComingSoon) && (
            <Watch>
              <VideoButton
                handleClick={videoComingSoon ? (f) => f : () => handlePopup()}
                text={videoComingSoon ? t.videoComingSoon : t.watchFilm}
                transparent
                disabled={videoComingSoon}
                vertical
              />
            </Watch>
          )}
          {popupVideo && (
            <VideoPopup
              active={popupActive}
              src={popupVideo}
              handleClose={handlePopup}
            />
          )}
        </Root>
      )}
    </Intl>
  );
};

export default Hero;

Hero.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.object,
  video: PropTypes.string,
  popupVideo: PropTypes.string,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 94vh;
  overflow: hidden;

  @media ${media.tablet} {
    height: 100vh;
  }
  @media ${media.desktop} {
    height: 100vh;
  }
`;

const Heading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.moss};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    ${vw('top', -100)}
  }
`;

const LineAnimation = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  51% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
`;

const LineAnimationCss = css`
  animation-name: ${LineAnimation};
  animation-duration: 3s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-iteration-count: infinite;
  animation-delay: 0s;
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${vwMobile(24)};
  width: 1px;
  height: ${vwMobile(50)};
  margin: 0 auto;
  background-image: linear-gradient(
    179deg,
    #f5d696 -3%,
    #f4e4a8 21%,
    #edd397 47%,
    #deb66d 67%,
    #dea960 100%
  );
  ${LineAnimationCss}
  @media ${media.tablet} {
    bottom: ${vwTablet(110)};
    height: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    bottom: ${vwDesktop(24)};
    height: ${vwDesktop(50)};
    transform: scaleY(0);
  }
`;

const Watch = styled.div`
  position: absolute;
  bottom: ${vwMobile(92)};
  left: 50%;
  transform: translateX(-50%);
  @media ${media.tablet} {
    bottom: ${vwTablet(270)};
  }
  @media ${media.desktop} {
    bottom: ${vwDesktop(142)};
  }
`;
