import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Appointment = () => {
  return (
    <Intl>
      {(t) => (
        <Page hideRegister>
          <Root>
            <h4 className="subheader moss">{t.appointment.subtitle}</h4>
            <h3>{t.appointment.title}</h3>
            <Wrapper>
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=21639809&amp;appointmentType=19435902"
                title="Schedule Appointment"
                width="100%"
                height="800"
                frameBorder="0"
              ></iframe>
              <script
                src="https://embed.acuityscheduling.com/js/embed.js"
                type="text/javascript"
              ></script>
            </Wrapper>
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.div`
  padding: ${vwMobile(40)} ${vwMobile(16)};
  margin-bottom: ${vwMobile(-80)};
  h3 {
    margin: ${vwMobile(32)} 0 ${vwMobile(60)};
  }
  h3 span {
    font-style: italic;
  }
  p span {
    font-weight: 600;
  }
  p {
    margin-bottom: ${vwMobile(40)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(40)};
    margin-bottom: ${vwTablet(-80)};
    h3 {
      margin: ${vwTablet(40)} 0 ${vwTablet(80)};
      max-width: ${vwTablet(456)};
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(164)};
    margin-bottom: ${vwDesktop(0)};
    h3 {
      margin: ${vwDesktop(80)} 0 ${vwDesktop(40)};
      max-width: ${vwDesktop(456)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
    }
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    margin: ${vwDesktop(20)};
    box-shadow: 0 ${vwDesktop(4)} ${vwDesktop(12)} 1px rgba(0, 0, 0, 0.06),
      0 ${vwDesktop(6)} ${vwDesktop(16)} 0 rgba(0, 0, 0, 0.1);
  }
`;

export default Appointment;
