import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';

import { vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Hamburger = (props) => {
  const { isMenuOpen, handleClick } = props;
  return (
    <Intl>
      {(t) => (
        <Root onClick={handleClick}>
          <Image>
            <img
              src={require('src/assets/images/icons/menu-gold.svg')}
              alt="Open Menu"
              style={{ opacity: isMenuOpen ? 0 : 1 }}
            />
            <img
              src={require('src/assets/images/icons/close-gold.svg')}
              alt="Close Menu"
              style={{ opacity: isMenuOpen ? 1 : 0 }}
            />
          </Image>
          <Text>
            <p className="menu-page" style={{ opacity: isMenuOpen ? 0 : 1 }}>
              {t.menuOpen}
            </p>
            <p className="menu-page" style={{ opacity: isMenuOpen ? 1 : 0 }}>
              {t.menuClose}
            </p>
          </Text>
        </Root>
      )}
    </Intl>
  );
};

Hamburger.propTypes = {
  isMenuOpen: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  display: flex;
  align-items: center;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Image = styled.div`
  width: ${vwMobile(16)};
  height: ${vwMobile(16)};
  margin-right: ${vwMobile(8)};
  position: relative;
  @media ${media.tablet} {
    width: ${vwTablet(24)};
    height: ${vwTablet(24)};
    margin-right: ${vwTablet(12)};
  }
`;

const Text = styled.div`
  position: relative;
  p:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
`;

export default Hamburger;
