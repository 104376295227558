import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Page from 'components/Page';
import Hero from './sections/Hero';
import LinkBlock from 'components/LinkBlock';
import ContentBlock from 'components/ContentBlock';
import Illustration from 'components/Illustration';
import Intro from './sections/Intro';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Home = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            {window.__PRERENDERING && <Overlay />}
            <Hero />
            <Page>
              <Intro />
              <LinkBlock
                subtitle={t.home.linkBlock.subtitle}
                title={t.home.linkBlock.title}
                body={t.home.linkBlock.body}
                buttonLabel={t.home.linkBlock.button}
                bgImageMobile="home/jinray-circular-balcony.jpg"
                bgImageDesktop="home/jinray-circular-balcony.jpg"
                url="/philosophy"
                noLogo
                small
              />
              <Space />
              {t.home.contentBlock.map((section, i) => (
                <ContentBlock
                  key={i}
                  subtitle={section.subtitle}
                  title={section.title}
                  body={section.body}
                  button={section.button}
                  images={section.images}
                  settings={section.settings}
                  leftPadding
                >
                  {i === 0 && (
                    <Illustration
                      mobile={{
                        width: 211,
                        height: 160,
                        top: 558,
                        left: 159,
                      }}
                      tablet={{
                        width: 422,
                        height: 320,
                        top: 833.2,
                        left: 385,
                      }}
                      desktop={{
                        width: 422,
                        height: 320,
                        top: 510,
                        left: 1019,
                      }}
                      distance={-150}
                      image="home/illustration-horseback-riding.png"
                    />
                  )}
                  {i === 1 && (
                    <Illustration
                      mobile={{
                        width: 246.4,
                        height: 360,
                        top: 295,
                        left: 0,
                      }}
                      tablet={{
                        width: 575,
                        height: 840,
                        top: 423,
                        left: 0,
                      }}
                      desktop={{
                        width: 575,
                        height: 840,
                        top: -40,
                        left: 0,
                      }}
                      distance={-100}
                      image="home/illustration-kerrisdale-full.png"
                    />
                  )}
                </ContentBlock>
              ))}
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.main`
  @media ${media.desktop} {
    /* margin-top: -100vh; */
  }
`;

const Space = styled.div`
  padding-top: ${vwMobile(80)};
  @media ${media.tablet} {
    padding-top: ${vwTablet(160)};
  }
  @media ${media.desktop} {
    padding-top: ${vwDesktop(160)};
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: black;
  transition: 0.4s ${({ theme }) => theme.animation.timingFunction};
  pointer-events: none;
`;

export default Home;
